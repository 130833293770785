
<script setup>
    import { computed } from "vue";
    import { getVueRouter } from "mobile.utils.ts";

    const props = defineProps({
        href: { type: String },
        mHref: { },
    });

    const router = getVueRouter();
    
    const hrefKey = computed(() => router ? 'to' : 'href');
    const hrefVal = computed(() => router ? props.mHref : props.href);
</script>

<template>
    <component :is="router ? 'router-link' : 'a'" :[hrefKey]="hrefVal" v-bind="$attrs">
        <slot />
    </component>
</template>
